import "./NavigationBar.css";
import Icon from "../../Images/NavigationImages/Icon.svg";
import TechFarm from "../../Images/NavigationImages/TechFarm.png";

export default function NavigationBar() {
  return (
    <div className="NavigationBar">
      <div className="NavigationBarMain">
        {/* div要素でimgを囲むのはブロック要素にするため */}
        <div>
          <img className="NavigationBarIcon" src={Icon} alt="LOGO" />
        </div>
        <div>
          <img className="NavigationBarName" src={TechFarm} alt="TechFarm" />
        </div>
        <div className="NavigationBarAnchor">
          <a href="#LearningStyle">学び方について</a>
        </div>
        <div className="NavigationBarAnchor">
          <a href="#Competition">目標に関して</a>
        </div>
        <div className="NavigationBarAnchor">
          <a href="#MembersComments">メンバーの声</a>
        </div>
        <div className="NavigationBarAnchor">
          <a href="#PricingPlan">料金体系</a>
        </div>
        <div className="NavigationBarAnchor">
          <a href="#Contraction">ご契約の流れ</a>
        </div>
      </div>
    </div>
  );
}
