import "./Contraction.css";
import ContractionProcessElement from "./ContractionProcessElement/ContractionProcessElement";

export default function Contraction() {
  const ContractionProcessArray = [
    { message: "無料体験日の選択をクリックする" },
    { message: "申込フォームから日程を選択して送信する" },
    { message: "参加日の確認メールと参加辞退用URLが届く" },
    { message: "当日親子で参加する" },
    { message: "契約用パンフレットとチャットIDを受け取る" },
    { message: "スタッフとチャットしながら契約" },
  ];

  return (
    <div className="Contraction" id="Contraction">
      <div className="ContractionTitle">「契約までの流れ」</div>
      <div className="ContractionMain">
        {ContractionProcessArray.map((prop, id) => {
          return (
            <ContractionProcessElement
              message={prop.message}
              index={id + 1}
              key={id}
            />
          );
        })}
      </div>
    </div>
  );
}
