import "./Thumbnail.css";

export default function Thumbnail() {
  return (
    <div className="Thumbnail">
      <div className="ThumnailTextMargin">
        <div className="ThumbnailText">
          <div>元地域おこし協力隊</div>
          <div>企業デジタル研修担当が作る</div>
        </div>
        <div className="ThumbnailText">
          <div>自然に学習する為の</div>
          <div>最新の学習システムを駆使した</div>
        </div>
        <div className="ThumbnailText">
          <div>地域でもハイクオリティの</div>
          <div>スマートスクール</div>
        </div>
      </div>
    </div>
  );
}
