import "./LearningStyle.css";
import liveGameIcon from "../../Images/LearningStyleImage/likeGameImage.png";

export default function LearningStyle() {
  return (
    <div className="LearningStyle" id="LearningStyle">
      <div className="LearningStyleTitle">
        「好きから始めるスマートスクール」
      </div>
      <div className="LearningStyleMain">
        <div className="LearningStyleCommentLayout">
          <div className="LearningStyleTextTitle"> 【学習スタイル】 </div>
          <div className="LearningStyleTextSubTitle">
            子ども達にとって馴染み深い「SNS」や「Amazon風ショップ」や「Paypay風決済」などを作ってみながら基礎学習をしていきます。
          </div>
          <div className="LearningStyleTextDescription">
            もちろん、学校のテスト対策も日頃から行います。
          </div>
          <div className="LearningStyleTextDescription">
            特に、国語以外の４教科に関しては準備があります。
          </div>
          <div className="LearningStyleTextTitle">【時間】</div>
          <div className="LearningStyleTextDescription">
            火曜日と木曜日が対象です。
          </div>
          <div className="LearningStyleTextDescription">
            祝日は休みです。（料金はその分引かれます）
          </div>
        </div>
        <div className="LearningStyleImageLayout">
          <img
            src={liveGameIcon}
            alt="smartPhoneImage"
            className="LearningStyleImage"
          />
        </div>
      </div>
    </div>
  );
}
