import "./PricingPlan.css";

export default function PricingPlan() {
  return (
    <div className="PricingPlan" id="PricingPlan">
      <div className="PricingPlanTitle">「料金体系」</div>
      <div className="PricingPlanMain">
        <div className="PricingPlanCommentLayout">
          <div className="PricingPlanTextTitle">スタートプラン</div>
          <div className="PricingPlanTextDescriptions">
            <div className="PricingPlanTextDescription">月額税込XX,000</div>
            <div className="PricingPlanTextDescription">
              一回辺り:X,0000円税込
            </div>
            <div className="PricingPlanTextDescription">
              週2回のプログラミング教材学習
            </div>
            <div className="PricingPlanTextDescription">
              月一回の学習カウンセリング
            </div>
            <div className="PricingPlanTextDescription">
              数学・理科・英語のワーク管理
            </div>
            <div className="PricingPlanTextDescription">
              課題提出アプリの提供
            </div>
          </div>
        </div>
        <div className="PricingPlanCommentLayout">
          <div className="PricingPlanTextTitle">アプリ開発プラン</div>
          <div className="PricingPlanTextDescriptions">
            <div className="PricingPlanTextDescription">月額税込XX,X00円</div>
            <div className="PricingPlanTextDescription">一回:XX00円</div>
            <div className="PricingPlanTextDescription">
              週2回のアプリ開発学習
            </div>
            <div className="PricingPlanTextDescription">
              月一回の学習カウンセリング
            </div>
            <div className="PricingPlanTextDescription">
              数学・理科・英語のワーク管理
            </div>
            <div className="PricingPlanTextDescription">
              課題提出アプリの提供
            </div>
          </div>
        </div>
        <div className="PricingPlanCommentLayout">
          <div className="PricingPlanTextTitle">家庭学習プラン</div>
          <div className="PricingPlanTextDescriptions">
            <div className="PricingPlanTextDescription">月額X,000</div>
            <div className="PricingPlanTextDescription">
              一回X,X00円での参加
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
