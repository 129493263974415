import "./ContractionProcessElement.css";

export default function ContractionProcessElement(props) {
  return (
    <div className="ContractionProcessElement">
      <div className="ContractionProcessElementIndex">{props.index}</div>
      <div className="ContractionProcessMessage">{props.message}</div>
    </div>
  );
}
