import "./MembersComments.css";
import User1 from "../../Images/MembersCommentsImage/User1.png";
import User2 from "../../Images/MembersCommentsImage/User2.png";

export default function MembersComments() {
  return (
    <div className="MembersComments" id="MembersComments">
      <div className="MembersCommentsTitle">「メンバーのコメント」</div>

      <div className="MembersCommentsMain">
        <div className="MembersCommentsStyleImageLayout1">
          <img
            className="MembersCommentsStyleImage"
            src={User1}
            alt="ユーザーイメージ"
          />
        </div>
        <div className="MembersCommentsTextLayout1">
          <div className="MembersCommentsTextTitle">Aさん</div>
          <div className="MembersCommentsDescription">
            テックファームは佐用町星降る町のビジネスコンテストと協力関係を作っています。
            本格的なプログラミングを学び、かつそれを実践的に考える事で社会に生きていく事ができるスキルにしていきます。
          </div>
        </div>
      </div>

      <div className="MembersCommentsMain">
        <div className="MembersCommentsTextLayout2">
          <div className="MembersCommentsTextTitle">Bさん</div>
          <div className="MembersCommentsDescription">
            テックファームは佐用町星降る町のビジネスコンテストと協力関係を作っています。
            本格的なプログラミングを学び、かつそれを実践的に考える事で社会に生きていく事ができるスキルにしていきます。
          </div>
        </div>
        <div className="MembersCommentsStyleImageLayout2">
          <img
            className="MembersCommentsStyleImage"
            src={User2}
            alt="ユーザーイメージ"
          />
        </div>
      </div>
    </div>
  );
}
