import Thumbnail from "./HomeView/Thumbnail/Thumbnail";
import Competition from "./HomeView/Competition/Competition";
import Contraction from "./HomeView/Contraction/Contraction";
import PricingPlan from "./HomeView/PricingPlan/PricingPlan";
import LearningStyle from "./HomeView/LearningStyle/LearningStyle";
import MembersComments from "./HomeView/MembersComments/MembersComments";
import Company from "./HomeView/Company/Company";
import NavigationBar from "./HomeView/NavigationBar/NavigationBar";
import "./App.css";

export default function App() {
  return (
    <div className="Home">
      <NavigationBar />
      <Thumbnail />
      <LearningStyle />
      <Competition />
      <MembersComments />
      <PricingPlan />
      <Contraction />
      <Company />
    </div>
  );
}
