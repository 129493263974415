import "./Company.css";
import mapImage from "../../Images/CompanyImage/map.png";

export default function Company() {
  return (
    <div className="Company" id="Company">
      <div className="CompanyTitle">「会社概要」</div>

      <div className="CompanyMain">
        <div className="CompanyCommentLayout">
          <div className="CompanyStyleImageLayout">
            <img
              className="CompanyStyleImage"
              src={mapImage}
              alt="地図イメージ"
            />
          </div>
          <div className="CompanyTextLayout">
            <div className="CompanyTextSubTitle">
              会社名:株式会社EnterPlannersFarm
            </div>
            <div className="CompanyTextSubTitle">代表取締役：中村拓也</div>
            <div className="CompanyTextSubTitle">
              事業内容:ITシステム開発事業IT教育
            </div>
            <div className="CompanyTextSubTitle">資本金:1,000,000円</div>
          </div>
          <div className="CompanyTextDescription"></div>
        </div>
        <div className="CompanyImageLayout"></div>
      </div>
    </div>
  );
}
