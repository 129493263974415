import "./Competition.css";
import CompetitionImage from "../../Images/CompetitionImage/bussinessComtest.png";

export default function Competition() {
  return (
    <div className="Competition" id="Competition">
      <div className="CompetitionTitle">「最終目標はコンペ出場！」</div>

      <div className="CompetitionMain">
        <div className="CompetitionStyleImageLayout">
          <img
            className="CompetitionStyleImage"
            src={CompetitionImage}
            alt="コンテストイメージ"
          />
        </div>
        <div className="CompetitionTextLayout">
          <div className="CompetitionTextTitle">
            テックファームは佐用町星降る町のビジネスコンテストと提携しています。
          </div>
          <br />
          <div className="CompetitionTextDescription">
            本格的なプログラミングを学び、かつそれを実践的に考える事で社会に生きていく事ができるスキル習得とその証明までを行います。
          </div>
          <br />
          <div className="CompetitionTextDescription">
            そして、学校推薦に活用してもらえる事を目指しています。
          </div>
        </div>
      </div>
    </div>
  );
}
